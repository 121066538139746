import { createBaseOptions, fetchJson, METHOD_GET } from "../utils/SuperAgentFetch";
import { config } from "../config/Config";


export const fetchDeployedProcesses = async (callback) => {
    /** @type RequestOptions */
    let options = createBaseOptions();
    options.method = 'GET';
    try {
        let processDefs = await fetchJson(`${config.url.API_URL}/bpmn/process-definition/statistics`, options);
        let json = await processDefs
        if (json && json._embedded && json._embedded.processDefinitionStatisticsResultDtoes) {
            let arr = [];
            json._embedded.processDefinitionStatisticsResultDtoes.forEach((process) => {
                process.versions = [];
                let isVersion = arr.find((el) => el.definition.key === process.definition.key)
                if (isVersion) {
                    if (isVersion.definition.version > process.definition.version) {
                        isVersion.versions.push(process)
                    }
                    else if (isVersion.definition.version < process.definition.version) {
                        let index = arr.indexOf(isVersion)
                        process.versions = isVersion.versions;
                        process.versions.push(isVersion);
                        arr.splice(index, 1)
                        arr.push(process)
                    }
                }
                else {
                    arr.push(process);
                }
            });
            let xmls =[];
            let allProcess = document.getElementsByClassName("allDeployedProceses");
            if (allProcess.length > 0) {
                allProcess[0].innerText = arr.length;
            }
            let itemCount = document.getElementsByClassName("countDeployedProcess");
            let count = 1;
            for(const el of arr){
                itemCount[0].innerText = count;
                let res = await fetchJson(`${config.url.API_URL}/bpmn/process-definition/${el.id}/xml `, options);
                let result = await res
                el.bpmn20Xml = result.bpmn20Xml;
                xmls.push(el);
                count++
                if(arr.length === xmls.length){
                    callback(xmls);
                }
               
            }
        }

    } catch (error) {
        console.log(error);
    }
};

