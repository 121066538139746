import React, { Component } from "react";
import { Button, Drawer, Modal, Input, Space, Tooltip } from 'antd';
import { DownloadOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { FormEditor } from '@bpmn-io/form-js-editor';
import "@bpmn-io/form-js/dist/assets/dragula.css";
import "@bpmn-io/form-js/dist/assets/form-js.css";
import "@bpmn-io/form-js/dist/assets/form-js-editor.css";
import debounce from 'lodash/debounce';
import { generateID } from "../utils/generateID";
import SaveFiles from "./SaveFiles";
import './bpmn-js-nyan/nyanForm.css';
import MainPanel from "../containers/MainPanel";
import MainContext from "../utils/Context";

class FormEdit extends Component {
	static contextType = MainContext;
	formEditor = null;
	constructor(props) {
		super(props);

		this.state = {
			schema: null,
			json: null,
			nameFile: null,
			saveSchema: false,
			visibleDel: false,
			visible: false,
		};
		this.nameFile = debounce(this.nameFile, 400);
	}
	componentDidMount() {
		if (this.props.data && !this.formEditor) {
			this.importForm(this.props.data)
			this.setState({
				saveSchema: true
			})
		}
		else if (!this.formEditor) {
			let id = generateID();
			let schema = {
				"type": "default",
				"id": `Form_${id}`,
				"exporter": {
					"name": "Camunda Modeler",
					"version": "5.0.0"
				},
				"components": [],
				"executionPlatform": "Camunda Platform",
				"executionPlatformVersion": "7.17.0"
			}
			this.importForm(schema);
		}
		this.setState({
			nameFile: this.props.name,
		});
	}
	componentDidUpdate(prevProps, prevState) {
		if (this.context.delIndex === this.props.index && this.state.visibleDel === false) {
			this.setState({ visibleDel: true })
		}
		if (this.context.activeIndex === this.props.index && this.context.eventModal === 'download' && !this.state.visible) {
			this.showModal();
		}
	}
	importForm = async (schema) => {
		this.formEditor = new FormEditor({
			container: document.querySelector('#' + this.props.formId)
		});
		try {
			await this.formEditor.importSchema(schema);
		} catch (err) {
			console.log('importing form failed', err);
		}
		this.formEditor.on("changed", () => {
			if (this.state.saveSchema === true) {
				this.context.saveItems("savedForm", null, this.props.itemKey, null, 'edit');
				this.setState({ saveSchema: false });
			}
		});
	}
	downloadForm = () => {
		const schema = JSON.stringify(this.formEditor.saveSchema());
		let name = this.state.nameFile.includes('.form')
		name = name ? this.state.nameFile : this.state.nameFile + ".form";
		this.setEncoded(schema, name);
	}

	setEncoded = (data, name) => {
		var a = document.createElement("a");
		a.href = "data:application/bpmn20-xml;charset=UTF-8," + encodeURIComponent(data);
		a.download = name;
		a.click();

	}
	showJson = () => {
		if (this.state.json) {
			this.setState({ json: null });
		}
		else {
			const schema = JSON.stringify(this.formEditor.saveSchema());
			this.setState({ json: schema })
		}
	}
	save = (name) => {
		const schema = this.formEditor.saveSchema();
		if (this.state.visibleDel) {
			this.context.removeTab(this.props.index)
		}
		this.setState({
			saveSchema: true,
			nameFile: name,
			visibleDel: false,
		});
		let data = { id: this.formEditor._state.schema.id, data: schema }
		this.context.saveItems("savedForm", name, this.props.itemKey, data);
	}
	onClose = () => {
		this.setState({ json: null });
	}
	showModal = (e) => {
		this.setState({ visible: true });
	}
	handleCancel = () => {
		this.setState({ visible: false });
		if (this.context.eventModal === 'download') {
			this.context.offModal()
		}
	}
	nameFile = (name) => {
		let newName = name.target.value.includes('.form');
		newName = newName ? name.target.value : name.target.value + '.form';
		this.setState({
			nameFile: newName,
		});
	}
	handleCancelDel = (index) => {
		this.context.removeTab(index);
		this.setState({ visibleDel: false });
	}

	render = () => {
		if (this.formEditor) {
			console.log();
		}

		const sharedProps = {
			style: {
				width: '100%',
			},
			defaultValue: this.state.nameFile,
		};

		let modalDelete = <Modal title='Close file?' destroyOnClose={true} open={this.state.visibleDel}
			footer={[
				<Button key="cancel" onClick={() => this.handleCancelDel('cancel')}>
					Cancel
				</Button>,
				<SaveFiles name={this.state.nameFile} mode={'del'} save={this.save} type={"savedBpmn"} format={'.bpmn'} />,
				<Button
					key="dontSave"
					type="primary"
					onClick={() => this.handleCancelDel(this.props.index)}
				>
					Don't Save
				</Button>,
			]} onCancel={() => this.handleCancelDel('cancel')}>
			<ExclamationCircleFilled style={{ fontSize: '24px', color: 'blue', marginRight: '10px' }} /><span style={{ fontSize: '16px' }}>Save changes to "{this.state.nameFile}" before closing? </span>
		</Modal>;


		let modal =
			<Modal title="Download file" destroyOnClose={true} open={this.state.visible} onCancel={this.handleCancel} footer={false}>
				<div>Name file</div>
				<Input onChange={this.nameFile} {...sharedProps}></Input>
				<div style={{ display: 'flex', justifyContent: 'end', marginTop: '20px' }}>
					<Button type="primary" disabled={!this.state.nameFile} onClick={() => this.downloadForm()}><DownloadOutlined style={{ fontSize: '18px' }} />Download</Button>
				</div>
			</Modal>


		let menu = (<Space size="middle" style={{ display: 'flex', height: '47px', backgroundColor: 'hsl(220deg 12% 95%)' }}>
			<Tooltip placement="top" title="Show json">
				<Button type="link" onClick={this.showJson} style={{ color: '#595959' }}>{this.state.json ? "Form" : "JSON"}</Button>
			</Tooltip>
			<Tooltip placement="top" title="Download">
				<Button type="link" onClick={() => this.showModal()}><DownloadOutlined style={{ color: 'black', fontSize: '18px' }} /></Button>
			</Tooltip>
			<SaveFiles name={this.state.nameFile} save={this.save} type={"savedForm"} format={'.form'} index={this.props.index}  />
		</Space>);

		let drawer = <Drawer
			title="View Json"
			placement="left"
			width={"100%"}
			onClose={this.onClose}
			open={this.state.json}
			size='small'
			getContainer={false}
			style={{
				position: 'absolute',
				overflowY: 'hidden'
			}}
		>
			<pre style={{  overflow:'auto', padding: "1em",   fontFamily: "monospace", whiteSpace: "pre-wrap"}}>{this.state.json}</pre>
		</Drawer>

		return (
			<>
				{modalDelete}
				{modal}
				<div className="site-drawer-render-in-current-wrapper">
					{drawer}

					<div className="panelNyan"  >
						<MainPanel
							index={this.props.index}
							activeButton={this.state.activeButton}
							modeler={this.modeler}
						/>
					</div>
					<div className="FormModeler" id={this.props.formId} ></div>

				</div>
				{menu}
			</>
		);
	};
}

export default FormEdit;
