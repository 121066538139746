import BaseRenderer from 'diagram-js/lib/draw/BaseRenderer';

import {
    append as svgAppend,
    attr as svgAttr,
    create as svgCreate,
    remove as svgRemove
} from 'tiny-svg';
import iconConnector from './customIcon/connector';
import iconMail from './customIcon/email';
import userSwitch from './customIcon/userSwitch';
import colectionId from './customIcon/colectionId';
import iconDraft from './customIcon/draft';
import iconMergeDraft from './customIcon/mergeDraft';
import iconAphinit from './customIcon/aphinitLogo';
import iconChangeObjectStatus from './customIcon/changeObjectStatus';
import iconChangeObjectRights from './customIcon/changeObjectRights';
import iconEntryDetail from './customIcon/entryDetail';
import iconEntryIdsCollection from './customIcon/entryIdsCollection';
import iconEntryRelations from './customIcon/entryRelations';
import iconUserIdentity from './customIcon/userIdentity';
import iconEntryEdit from './customIcon/entryEdit';
import iconEntryNew from './customIcon/entryNew';
import iconEmpty from './customIcon/empty';
import {
    getRoundRectPath
} from 'bpmn-js/lib/draw/BpmnRenderUtil';

import { is } from 'bpmn-js/lib/util/ModelUtil';
import { isAny } from 'bpmn-js/lib/features/modeling/util/ModelingUtil';

const HIGH_PRIORITY = 2500,
    TASK_BORDER_RADIUS = 8;

export default class CustomRenderer extends BaseRenderer {
    constructor(eventBus, bpmnRenderer) {
        super(eventBus, HIGH_PRIORITY);
        this.bpmnRenderer = bpmnRenderer;
    }

    canRender(element) {
        // only render tasks and events (ignore labels)
        return isAny(element, ['bpmn:ServiceTask', 'bpmn:Event']) && !element.labelTarget;
    }

    drawShape(parentNode, element) {
        const shape = this.bpmnRenderer.drawShape(parentNode, element);
        if (is(element, 'bpmn:ServiceTask') && element.businessObject.extensionElements && element.businessObject.extensionElements.values[0].connectorId === 'http-connector') {
            const image = svgCreate('image');
            svgAttr(image, {
                href: iconConnector.dataURL, // путь к изображению иконки
                width: 35,
                height: 25,
            });

            svgAttr(image, {
                transform: 'translate(-4, 1)',
                rx: 10,
                ry: 10,
            });
            const rect = drawRect(parentNode, 100, 80, TASK_BORDER_RADIUS, '#096dd9');

            prependTo(rect, parentNode);
            svgRemove(shape);


            svgAppend(parentNode, image);

            return shape;
        }
        if (is(element, 'bpmn:ServiceTask') && element.businessObject && element.businessObject.delegateExpression) {
            let imageIcon = {
                href: iconEmpty.dataURL, // путь к изображению иконки
                width: 35,
                height: 25,
            }
            let transform = {
                transform: 'translate(-4, 1)',
                rx: 10,
                ry: 10,
            };
            let iconA = false;

            switch (element.businessObject.delegateExpression) {
                case "${mailSenderDelegate}":
                    imageIcon.href = iconMail.dataURL;
                    break;
                case "${mailSenderWithEntryDelegate}":
                    imageIcon.href = iconMail.dataURL;
                    iconA = true;
                    break;
                case "${PreviousTaskUserID}":
                    imageIcon.href = userSwitch.dataURL;
                    break;
                case "${userIDsCollection}":
                    imageIcon.href = colectionId.dataURL;
                    break;
                case "${createEntryDraftDelegate}":
                    imageIcon.href = iconDraft.dataURL;
                    iconA = true;
                    break;
                case "${mergeEntryDraftDelegate}":
                    imageIcon.href = iconMergeDraft.dataURL;
                    iconA = true;
                    break;
                case "${changeStatusDelegate}":
                    imageIcon.href = iconChangeObjectStatus.dataURL;
                    iconA = true;
                    break;
                case "${changeRightsDelegate}":
                    imageIcon.href = iconChangeObjectRights.dataURL;
                    iconA = true;
                    break;
                case "${entryDetailMap}":
                    imageIcon.href = iconEntryDetail.dataURL;
                    iconA = true;
                    break;
                case "${entryChildrenIDsCollection}":
                    imageIcon.href = iconEntryIdsCollection.dataURL;
                    iconA = true;
                    break;
                case "${entryRelations}":
                    imageIcon.href = iconEntryRelations.dataURL;
                    iconA = true;
                    break;
                case "${entryRelatedIdentityId}":
                    imageIcon.href = iconUserIdentity.dataURL;
                    iconA = true;
                    break;
                case "${entryRelatedIdentity}":
                    imageIcon.href = iconUserIdentity.dataURL;
                    iconA = true;
                    break;
                case "${entryUpdate}":
                    imageIcon.href = iconEntryEdit.dataURL;
                    iconA = true;
                break;
                case "${entryNew}":
                    imageIcon.href = iconEntryNew.dataURL;
                    iconA = true;
                break;
                default:
                    break;
            }
            const image = svgCreate('image');
            svgAttr(image, imageIcon);

            svgAttr(image, transform);
            const rect = drawRect(parentNode, 100, 80, TASK_BORDER_RADIUS, '#096dd9');

            prependTo(rect, parentNode);
            svgRemove(shape);


            svgAppend(parentNode, image);
            if (iconA) {
                const image2 = svgCreate('image');
                svgAttr(image2, {
                    href: iconAphinit.dataURL, // путь к новой иконке
                    width: 35,
                    height: 25,
                });
                svgAttr(image2, {
                    transform: 'translate(63, 50)', // установите новые координаты для новой иконки
                    rx: 10,
                    ry: 10,
                });
                svgAppend(parentNode, image2);
            }

            return shape;
        }
        if (is(element, 'bpmn:ServiceTask') && element.businessObject.delegateExpression === "${entryChildrenIDsCollection}") {
            const image = svgCreate('image');
            svgAttr(image, {
                href: iconDraft.dataURL, // путь к изображению иконки
                width: 35,
                height: 25,
            });

            svgAttr(image, {
                transform: 'translate(-4, 1)',
                rx: 10,
                ry: 10,
            });
            const rect = drawRect(parentNode, 100, 80, TASK_BORDER_RADIUS, '#096dd9');

            prependTo(rect, parentNode);
            svgRemove(shape);


            svgAppend(parentNode, image);

            const image2 = svgCreate('image');
            svgAttr(image2, {
                href: iconAphinit.dataURL, // путь к новой иконке
                width: 35,
                height: 25,
            });
            svgAttr(image2, {
                transform: 'translate(63, 50)', // установите новые координаты для новой иконки
                rx: 10,
                ry: 10,
            });
            svgAppend(parentNode, image2);

            return shape;
        }


        return this.bpmnRenderer.drawShape(parentNode, element);
    }


    getShapePath(shape) {
        if (is(shape, 'bpmn:ServiceTask')) {
            return getRoundRectPath(shape, TASK_BORDER_RADIUS);
        }

        return this.bpmnRenderer.getShapePath(shape);
    }
}

CustomRenderer.$inject = ['eventBus', 'bpmnRenderer'];

// helpers //////////

// copied from https://github.com/bpmn-io/bpmn-js/blob/master/lib/draw/BpmnRenderer.js
function drawRect(parentNode, width, height, borderRadius, strokeColor) {
    const rect = svgCreate('rect');

    svgAttr(rect, {
        width: width,
        height: height,
        rx: borderRadius,
        ry: borderRadius,
        stroke: strokeColor || '#000',
        strokeWidth: 2,
        fill: '#fff',
    });

    svgAppend(parentNode, rect);

    return rect;
}

// copied from https://github.com/bpmn-io/diagram-js/blob/master/lib/core/GraphicsFactory.js
function prependTo(newNode, parentNode, siblingNode) {
    parentNode.insertBefore(newNode, siblingNode || parentNode.firstChild);
}    