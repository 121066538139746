import React, { Component } from "react";
import { Button, Drawer, Modal, Input, Space, Tooltip, Alert } from 'antd';
import { DownloadOutlined, BoxPlotOutlined, PictureOutlined, ExclamationCircleFilled, BugFilled } from '@ant-design/icons';
import Modeler from 'camunda-bpmn-js/lib/camunda-platform/Modeler';
import { is } from "bpmn-js/lib/util/ModelUtil";
import {
	BpmnPropertiesPanelModule,
	BpmnPropertiesProviderModule,
	CamundaPlatformPropertiesProviderModule,
} from 'bpmn-js-properties-panel';
import BpmnColorPickerModule from 'bpmn-js-color-picker';
import ElementTemplatesIconsRenderer from '@bpmn-io/element-templates-icons-renderer';
import TokenSimulationModule from 'bpmn-js-token-simulation';
import ConnectorsExtensionModule from 'bpmn-js-connectors-extension';
import CamundaBpmnModdle from 'camunda-bpmn-moddle/resources/camunda.json'
import lintModule from "bpmn-js-bpmnlint";
import bpmnlintConfig from "./bpmnlintconfig-packed";
import 'camunda-bpmn-js/dist/assets/camunda-platform-modeler.css';
import "bpmn-js/dist/assets/diagram-js.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css";
import '../App.css'
import "bpmn-js-properties-panel/dist/assets/properties-panel.css";
import "bpmn-js-properties-panel/dist/assets/element-templates.css";
import "bpmn-js/dist/assets/diagram-js.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css";
import 'bpmn-js-connectors-extension/dist/connectors-extension.css';
import "bpmn-js-token-simulation/assets/css/bpmn-js-token-simulation.css";
import "bpmn-js-bpmnlint/dist/assets/css/bpmn-js-bpmnlint.css";
import debounce from 'lodash/debounce';

import Deploy from "./Deploy";
import StartProcess from "./StartProcess";
import { generateID } from "../utils/generateID";
import SaveFiles from "./SaveFiles";
import MainPanel from "../containers/MainPanel";
import customRendererModule from "./custom"
import { downloadImage } from "../utils/DownloadImageBpmn.js";
import { isUndefined } from "../utils/JsObjectHelper.js";
import cloneDeep from "lodash.clonedeep";
import MainContext from "../utils/Context";
import { notificationError } from "../utils/NotificationsHelper";
import { overlaysMain } from "./overlays/MainFunctionOverlays";


class BpmnMyModeler extends Component {
	static contextType = MainContext;
	modeler = null
	container = document.getElementById("canvas");
	constructor(props) {
		super(props);

		this.state = {
			saveBpmn: false,
			diagram: [],
			xml: null,
			visible: false,
			nameFile: null,
			viewXml: false,
			visibleDel: false,
			activeButton: null,
			errors: [],
			drawerError: false
		};
		this.nameFile = debounce(this.nameFile, 400);
	}
	componentDidMount() {
		let templates = require('./templates/element-templates.json');
		let initialDiagram = null;
		if (this.props.data && this.state.diagram.length === 0) {
			initialDiagram = this.props.data
			this.setState({ saveBpmn: true })
		}
		else if (this.state.diagram.length === 0) {
			let id = generateID();
			initialDiagram = (`<?xml version="1.0" encoding="UTF-8"?>
      <bpmn:definitions xmlns:bpmn="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" id="Definitions_1nkrrhg" targetNamespace="http://bpmn.io/schema/bpmn" xmlns:modeler="http://camunda.org/schema/modeler/1.0" exporter="Camunda Modeler" exporterVersion="5.0.0" modeler:executionPlatform="Camunda Platform" modeler:executionPlatformVersion="7.17.0">
        <bpmn:process id="Process_${id}" isExecutable="true">
          <bpmn:startEvent id="StartEvent_1" />
        </bpmn:process>
        <bpmndi:BPMNDiagram id="BPMNDiagram_1">
          <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="Process_${id}">
            <bpmndi:BPMNShape id="_BPMNShape_StartEvent_2" bpmnElement="StartEvent_1">
              <dc:Bounds x="179" y="159" width="36" height="36" />
            </bpmndi:BPMNShape>
          </bpmndi:BPMNPlane>
        </bpmndi:BPMNDiagram>
      </bpmn:definitions>`)

		}
		this.setState({
			nameFile: this.props.name
		});
		this.openDiagram(initialDiagram, templates)
	};
	componentDidUpdate(prevProps, prevState) {
		if (this.context.delIndex === this.props.index && this.state.visibleDel === false) {
			this.setState({ visibleDel: true })
		}
		const bpmnLint = this.modeler.get('linting');
		if (this.context.mySetup && bpmnLint._active !== this.context.mySetup.value.lint) {
			if (this.context.mySetup.value.lint === true) {
				bpmnLint._setActive(true);
			}
			if (this.context.mySetup.value.lint === false) {
				bpmnLint._setActive(false);
			}
		}
		if (this.context.activeIndex === this.props.index && this.context.eventModal === 'download' && !this.state.visible) {
			this.showModal();
		}
	}
	activateButton = (contexElement) => {
		console.log(contexElement);
		//Access the overlays service 
		let overlays = this.modeler.get("overlays");

		let arrActiveBtn = ['bpmn:UserTask', 'bpmn:BusinessRuleTask', 'bpmn:StartEvent', 'bpmn:CallActivity', "bpmn:ScriptTask"];
		if (arrActiveBtn.find((el) => el === contexElement.type)) {
			this.setState({
				activeButton: true,
			});
		}
		else if (contexElement.type === "bpmn:ServiceTask") {
			let conector = contexElement.businessObject.extensionElements && contexElement.businessObject.extensionElements.values[0].connectorId === 'http-connector';
			let arrServisActive = ["${UserIDsColection}", "${mailSenderWithEntryDelegate}",
				"${mailSenderDelegate}", "${PreviousTaskUserID}",
				"${changeRightsDelegate}", "${changeStatusDelegate}",
				"${entryChildrenIDsCollection}", "${entryRelatedIdentityId}", "${entryRelatedIdentity}", "${entryRelations}","${entryDetailMap}","${entryUpdate}","${entryNew}"];
			
			if (arrServisActive.find((el) => el === contexElement.businessObject.delegateExpression) || conector) {
				if(contexElement.businessObject.delegateExpression ==="${entryDetailMap}" ){
					let entryList =this.context.entryTypeList.getData()
					overlaysMain(contexElement,overlays,entryList);
				}
				this.setState({
					activeButton: true,
				});
			}
		}
		else {
			if (this.state.activeButton) {
				overlays.clear();
				this.setState({ activeButton: null });
			}
		}
	}

	downloadSVG = async () => {
		const result = await this.modeler.saveSVG();
		let name = this.state.nameFile.slice(-5);
		var a = document.createElement("a");
		a.href = "data:application/bpmn20-xml;charset=UTF-8," + encodeURIComponent(result.svg);
		a.download = name + ".svg";
		a.click();
	};
	downloadPNG = async () => {
		var t = this;
		let name = this.state.nameFile.replace(/.bpmn$/, '');
		downloadImage(this.modeler, name, '.png', t)
	};
	downloadBpmn = async () => {
		let name = this.state.nameFile.includes(".bpmn");
		name = name ? this.state.nameFile : this.state.nameFile + ".bpmn";
		let result = await this.modeler.saveXML({ format: true });
		var a = document.createElement("a");
		a.href = "data:application/bpmn20-xml;charset=UTF-8," + encodeURIComponent(result.xml);
		a.download = name;
		a.click();
	};

	openDiagram = async (xml, templates) => {
		let activeLint = this.context.mySetup ? this.context.mySetup.value.lint : true;
		if (this.modeler == null) {
			this.modeler = new Modeler({
				container: '#' + this.props.canvasId,
				propertiesPanel: {
					parent: '#' + this.props.propertiesId
				},
				linting: {
					bpmnlint: bpmnlintConfig,
					active: activeLint
				},
				additionalModules: [
					customRendererModule,
					lintModule,
					BpmnPropertiesPanelModule,
					BpmnPropertiesProviderModule,
					CamundaPlatformPropertiesProviderModule,
					ConnectorsExtensionModule,
					ElementTemplatesIconsRenderer,
					TokenSimulationModule,
					BpmnColorPickerModule,
				],
				moddleExtensions: {
					camunda: CamundaBpmnModdle
				},
				keyboard: {
					bindTo: this.props.eventKeyboard ? document : false
				}
			});
			if (this.props.eventKeyboard) {
				this.props.offEventKeyboard()
			}
			const eventBus = this.modeler.get("eventBus");
			eventBus.on("element.changed", context => {
				this.activateButton(context.element);
				if (this.state.saveBpmn === true) {
					let processId = this.processId()
					let data = {
						id: processId,
						data: []
					}
					this.context.saveItems("savedBpmn", null, this.props.itemKey, data, 'edit');
					this.setState({ saveBpmn: false })
				}
			});
			eventBus.on("element.click", context => {
				this.activateButton(context.element);
			});
			this.modeler.get('connectorsExtension').loadTemplates(templates);
		}
		this.modeler
			.importXML(xml)
			.then(({ warnings }) => {
				const linter = this.modeler.get("linting");

				linter.lint(this.modeler.getDefinitions());
				if (warnings.length) {
					console.log("Warnings", warnings);
				}
				const canvas = this.modeler.get("modeling");
				canvas.setColor("CalmCustomerTask", {
					stroke: "green",
					fill: "yellow"
				});
			})
			.catch((err) => {
				let arr = cloneDeep(this.state.errors)
				var today = new Date();
				var now = today.toLocaleTimeString();
				arr.push({ message: 'File cannot be opened or file is corrupted', time: now });
				this.setState({
					errors: arr,
					drawerError: true
				});
			});
	};
	getXml = async () => {
		let result = await this.modeler.saveXML({ format: true });
		this.setState({
			xml: result.xml
		})
	}
	showXml = async () => {
		let result = await this.modeler.saveXML({ format: true });
		this.setState({
			viewXml: !this.state.viewXml,
			xml: result.xml
		})
	};
	onClose = () => {
		this.setState({ viewXml: false });
	};
	showModal = () => {
		this.setState({ visible: true });
	}
	handleCancel = () => {
		this.setState({ visible: false });
		if (this.context.eventModal === 'download') {
			this.context.offModal()
		}
	}
	nameFile = (name) => {
		this.setState({ nameFile: name.target.value });
	}
	processId = () => {
		let elementRegistry = this.modeler.get('elementRegistry');
		let process = elementRegistry.filter(function (element) {
			return is(element, "bpmn:Process");
		})
		let processIds = process.length > 0 ? process[0].id : undefined;
		if (isUndefined(processIds)) {
			processIds = [];
			let participants = elementRegistry.filter((element) => is(element, "bpmn:Participant"));
			for (let participant of participants) {
				processIds.push({
					name: isUndefined(participant.businessObject.name) ? participant.businessObject.id : participant.businessObject.name,
					id: participant.businessObject.processRef.id
				});
			}
		}
		return processIds
	}
	save = async (name) => {
		try {
			let result = await this.modeler.saveXML({ format: true });
			let processId = this.processId();
			let data = {
				id: processId,
				data: result.xml
			}
			this.context.saveItems("savedBpmn", name, this.props.itemKey, data)
			if (this.state.visibleDel) {
				this.context.removeTab(this.props.index)
			}
			this.setState({
				saveBpmn: true,
				nameFile: name,
				visibleDel: false,
			})
		}
		catch (err) {
			console.log(err);
		}
	}
	handleCancelDel = (index) => {
		this.context.removeTab(index);
		this.setState({ visibleDel: false });
	}
	showErrors = () => {
		this.setState({ drawerError: true });
	}
	closeErr = () => {
		this.setState({ drawerError: false });
	}
	errorsLog = (res) => {
		console.log(res);
		let arr = cloneDeep(this.state.errors)

		let err;
		try {
			err = JSON.parse(res.text);
			console.log(err);
		} catch (e) {
			console.error('Error parsing response text:', e);
			return;
		}

		var today = new Date();
		var now = today.toLocaleTimeString();
		err.time = now;
		arr.unshift(err);
		this.setState({
			errors: arr,
			drawerError: true
		});
	}




	clearErr = () => {
		this.setState({
			errors: [],
			drawerError: true
		})
	}

	render() {
		console.log(this.context.entryTypeList);
		const sharedProps = {
			style: { width: '100%', },
			defaultValue: this.state.nameFile,
		};
		let modalDelete = <Modal title='Close file?' destroyOnClose={true} open={this.state.visibleDel}
			footer={[
				<Button key="cancel" onClick={() => this.handleCancelDel('cancel')}>Cancel</Button>,
				<SaveFiles name={this.state.nameFile} mode={'del'} save={this.save} type={"savedBpmn"} format={'.bpmn'} />,
				<Button key="dontSave" type="primary" onClick={() => this.handleCancelDel(this.props.index)}>Don't Save</Button>,
			]} onCancel={() => this.handleCancelDel('cancel')}>
			<ExclamationCircleFilled style={{ fontSize: '24px', color: 'blue', marginRight: '10px' }} /><span style={{ fontSize: '16px' }}>Save changes to "{this.state.nameFile}" before closing? </span>
		</Modal>;
		///Modal Download file
		let modal = (
			<Modal title="Download file" destroyOnClose={true} open={this.state.visible} onCancel={this.handleCancel} footer={false}>
				<div>Name file</div>
				<Input onChange={this.nameFile} {...sharedProps}></Input>
				<div style={{ display: 'flex', justifyContent: 'end', marginTop: '20px' }}>
					<Button type="primary" disabled={!this.state.nameFile} onClick={this.downloadBpmn} style={{ marginRight: '5px' }}><BoxPlotOutlined style={{ fontSize: '18px' }} />BPMN</Button>
					<Button type="primary" disabled={!this.state.nameFile} onClick={this.downloadSVG} style={{ marginRight: '5px' }}><PictureOutlined style={{ fontSize: '18px' }} />SVG</Button>
					<Button type="primary" disabled={!this.state.nameFile} onClick={this.downloadPNG}><PictureOutlined style={{ fontSize: '18px' }} />PNG</Button>
				</div>
			</Modal>);
		///Bottom menu
		let menu = (<Space size="middle" style={{ display: 'flex', height: '47px', backgroundColor: 'hsl(220deg 12% 95%)' }}>
			<Tooltip placement="top" title="Show xml">
				<Button type="link" onClick={this.showXml} style={{ color: '#595959' }}>{this.state.viewXml ? "Diagram" : "XML"}</Button>
			</Tooltip>
			<Tooltip placement="top" title="Download">
				<Button type="link" onClick={this.showModal} style={{ color: '#595959' }}><DownloadOutlined style={{ color: '#595959', fontSize: '20px' }} /></Button>
			</Tooltip>
			<Deploy diagram={{ name: this.state.nameFile, contents: this.state.xml }} modeler={this.modeler} getDiagram={this.getXml} errorsLog={this.errorsLog} />
			<StartProcess diagram={{ name: this.state.nameFile, contents: this.state.xml }} modeler={this.modeler} getDiagram={this.getXml} errorsLog={this.errorsLog} />
			<SaveFiles name={this.state.nameFile} save={this.save} type={"savedBpmn"} format={'.bpmn'} index={this.props.index} />
			<Button type="link" onClick={this.showErrors} style={{ color: '#595959', fontWeight: 'bold' }}>Log <BugFilled style={{ color: '#595959', fontSize: '16px' }} /></Button>
		</Space>
		);

		let drawer = (<Drawer
			title="View XML"
			placement="left"
			width={"100%"}
			onClose={this.onClose}
			open={this.state.viewXml}
			size='small'
			getContainer={false}
			style={{
				position: 'absolute',
				overflowY: 'hidden'
			}}
		>
			<pre className="codePre"><code className="language-xml" >{this.state.xml}</code></pre>
		</Drawer>);
		let alertErr = []
		this.state.errors.forEach((el, i) => {
			alertErr.push(<Alert key={"err" + i} type="error" banner message={<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<span>{el.message}</span><span style={{ fontWeight: '500' }}>{el.time}</span>
			</div>}
			/>)
		})
		let drawerErr = (<Drawer
			title="Log"
			placement="bottom"
			width={"100%"}
			onClose={this.closeErr}
			open={this.state.drawerError}
			size='small'
			getContainer={false}
			style={{ position: 'absolute', overflowY: 'hidden' }}
			extra={<Button onClick={this.clearErr} style={{ fontWeight: 'bold' }}>Clear</Button>}>
			<Space direction="vertical" style={{ width: '100%', overflowY: 'scroll', height: '100%' }}>
				{alertErr}
			</Space>
		</Drawer>);

		let main = <>
			<div className="site-drawer-render-in-current-wrapper">
				{drawer}
				<div className="content" style={{ backgroundColor: 'white' }} >
					<div className="modeler"  >

						<div id={this.props.canvasId} className='BpmnModeler' >
							{drawerErr}
						</div>
						<MainPanel
							index={this.props.index}
							activeButton={this.state.activeButton}
							modeler={this.modeler}
							mySetup={this.context.mySetup} />

						<div className="properties-panel" id={this.props.propertiesId}></div>
					</div>
				</div>
			</div>
		</>
		return (
			<>{modalDelete}{modal}{main}{menu}</>

		);
	}
}


export default BpmnMyModeler;
