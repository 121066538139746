import React, { Component } from "react";
import { Form, Input, Card, InputNumber, Checkbox, Row, Col, Alert } from 'antd';
import { isUndefined } from "../../../utils/JsObjectHelper";

class AttributeForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            properties: null,
        };
    }
    componentDidMount() {
        if (this.props.properties) {
            this.setState({ properties: this.props.properties })
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.properties !== this.props.properties) {
            this.setState({ properties: this.props.properties })
        }
    }
    formItemsRender = (attributes) => {
        let formItems = [];
        let ElementForm = null;
        attributes.forEach((el, index) => {
            if (!isUndefined(el)) {
                let shouldAdd = true;
                const { minLength: min, maxLength: max, } = { el }
                switch (el.type) {
                    case "string":
                        ElementForm = (
                            <Form.Item
                                label={el.name}
                                name={el.techName}
                                key={index + el.techName}
                                rules={[
                                    { min },
                                    { max },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        );
                        break;
                    case "localizedString":
                        ElementForm = (
                            <Form.Item
                                label={el.name}
                                name={el.techName}
                                key={index + el.name}
                                rules={[
                                    { min },
                                    { max },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        );
                        break;
                    case "hyperlink":
                        ElementForm = (
                            <Input.Group compact>
                                <Form.Item
                                    name={[el.techName, 'label']}
                                    noStyle
                                >
                                    <Input style={{ width: '40%' }} placeholder="Label" />
                                </Form.Item>
                                <Form.Item
                                    name={[el.techName, 'url']}
                                    noStyle
                                >
                                    <Input style={{ width: '60%' }} placeholder="URL" />
                                </Form.Item>
                            </Input.Group>
                        );
                        break;
                    case "number":
                        ElementForm = (
                            <Form.Item
                                label={el.name}
                                name={el.techName}
                                key={index + el.name}
                                rules={[
                                    {
                                        pattern: /^(\d+|\$\{.*\}|\#\{.*\})$/,
                                        message: 'Enter either number, ${variable name} or #{variable name}',
                                    },
                                ]}
                            >
                                <Input
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            </Form.Item>
                        );
                        break;
                    case "boolean":
                        ElementForm = (
                            <Form.Item
                                label={el.name}
                                name={el.techName}
                                key={index + el.name}
                                valuePropName="checked"
                            >
                                <Checkbox />

                            </Form.Item>
                        );
                        break;
                    case "date":
                        ElementForm = (
                            <Form.Item
                                label={el.name}
                                name={el.techName}
                                key={index + el.name}
                                rules={[
                                    { min },
                                    { max },
                                ]}
                            >
                                <Input />

                            </Form.Item>
                        );
                        break;

                    default:
                        const alertMessage = `This type of attribute "${el.type}" is not yet supported for automatic changes, but we are working on it`;
                        const exists = formItems.some(item => {
                            return item && item.props && item.props.children && item.props.children.props && item.props.children.props.message === alertMessage;
                        });

                        if (!exists) {
                            ElementForm = (
                                <div style={{ display: "flex", flexDirection: "column", alignItems: 'center' }}>
                                    <Alert
                                        message={alertMessage}
                                        type="info"
                                        showIcon
                                    />
                                </div>
                            );
                        } else {
                            shouldAdd = false;
                        }
                        break;
                }
                if (ElementForm && shouldAdd) {
                    formItems.push(ElementForm);
                }
            }
        });
        return formItems
    }
    render() {
        let formAttributes = [];
        const properties = this.props.properties;

        if (properties) {
            const { editLayout, attributes } = properties;

            if (editLayout && editLayout.attributesDivDefinition && attributes) {
                editLayout.attributesDivDefinition.forEach((el) => {
                    const currentAttributes = el.attributeIndexes.map(index => attributes[index]).filter(attr => attr !== undefined);
                    let cardItem = (
                        <Col span={12}>
                            <Card
                                key={el.name}
                                title={el.name}
                                style={{ width: "100%" }}
                            >
                                {this.formItemsRender(currentAttributes)}
                            </Card>
                        </Col>
                    );
                    formAttributes.push(cardItem);
                });
            }
        }

        return <Row gutter={[16, 16]}>{formAttributes}</Row>;
    }

}
export default AttributeForm;