import React, { Component } from "react";
import { Button, Modal, Card, Row, Col, Select, Spin, Popconfirm, Input,Tooltip } from 'antd';
import { CloudServerOutlined } from '@ant-design/icons';
import "@bpmn-io/form-js/dist/assets/dragula.css";
import "@bpmn-io/form-js/dist/assets/form-js.css";
import "@bpmn-io/form-js/dist/assets/form-js-editor.css";
import MyDiagramViewer from "./MyDiagramViewer";
import { fetchDeployedProcesses } from "../../apicalls/DeployedProcesses";
import cloneDeep from "lodash.clonedeep";
import { GetXml } from "../../apicalls/GetXml";
import debounce from 'lodash/debounce';
const { Search } = Input;



class ModalDiagramView extends Component {
    formEditor = null;
    constructor(props) {
        super(props);

        this.state = {
            deployedProcess: [],
            visible: false,
            openPopconfirm: false,
            searchValue: ''
        };
        this.onChange = debounce(this.onChange, 400);
    }

    handleCancel = () => {
        this.setState({ visible: false, deployedProcess: [], searchValue: ''});

    }
    showModal = () => {
        fetchDeployedProcesses(this.addProcess);
        this.setState({ visible: true });
    }
    selectDiagram = (schema) => {
        this.props.openDeployedProcess(schema);
        this.setState({ visible: false, deployedProcess: [],searchValue: '' });
    }
    selectVersion = async (versionId) => {
        let arr = cloneDeep(this.state.deployedProcess);
        let selectedVersion = JSON.parse(versionId);
        let xml = await GetXml(selectedVersion.id);
        let process = arr.find((el) => el.definition.key === selectedVersion.key);
        let oldProcess = process.versions.find((el) => el.id === selectedVersion.id);
        oldProcess.versions = process.versions;
        let availableInVersions = oldProcess.versions.find((el) => el === process);
        if (!availableInVersions) {
            oldProcess.versions.push(process)
        }
        oldProcess.bpmn20Xml = xml.bpmn20Xml;
        arr[arr.indexOf(process)] = oldProcess;
        this.setState({ deployedProcess: arr })

    }

    addProcess = (process) => {
        this.setState({ deployedProcess: process });
    }
    showPopconfirm = () => {
        this.setState({ open: true });
    }
    handleOk = (schema) => {
        this.props.openDeployedProcess(schema);
        this.setState({ visible: false, open: false, deployedProcess: [], searchValue: ''});
    }
    handleCancelPopconfirm = () => {
        this.setState({ open: false });
    }
    onSearch = (value) => {
        this.setState({ searchValue: value })
    }
    onChange = (search) => {
        this.setState({ searchValue: search.target.value })
    }
    render = () => {
        console.log(this.state.deployedProcess);
        let card = []
       
        if (this.state.deployedProcess.length > 0) {
            this.state.deployedProcess.sort((a, b) => {
                if(a.definition.name && b.definition.name) {
                return a.definition.name.localeCompare(b.definition.name);
                } else if(a.definition.name) {
                return -1;
                } else if(b.definition.name) {
                return 1;
                } else {
                return 0;
                }
                }).filter((el) => 
                el.definition.name 
                ? el.definition.name.toLowerCase().includes(this.state.searchValue.toLowerCase()) 
                : el.definition.key.toLowerCase().includes(this.state.searchValue.toLowerCase())
            )
            .forEach((el, index) => {
                let options = [];
                el.versions.forEach((oldVersion) => options.push({ value: `{"id":"${oldVersion.definition.id}","key":"${el.definition.key}"}`, label: 'version ' + oldVersion.definition.version ,v:oldVersion.definition.version}));
                let viewOldVersion = el.versions.find((version) => version.definition.version > el.definition.version);
                options.sort((a,b)=>a.v - b.v)
                card.push(<Col key={el.id} span={6}>
                    <Card
                        extra={viewOldVersion ? <Popconfirm
                            title="This is old version are you sure?"
                            open={this.state.open}
                            okText='Yes'
                            onConfirm={() => this.handleOk(el)}
                            onCancel={this.handleCancelPopconfirm}
                        >
                            <Button type="link" style={{ color: 'orange' }} onClick={this.showPopconfirm}>
                                Open old version
                            </Button>
                        </Popconfirm> :
                            <a onClick={() => this.selectDiagram(el)}>Open</a>}
                        key={index}
                        title={<span key={el.id} style={{ fontSize: "14px" }}>{el.definition.name ? el.definition.name : el.definition.key} </span>}
                        actions={[
                            <Select
                                className="selectInCardDiagramView"
                                onChange={this.selectVersion}
                                placeholder={'version ' + el.definition.version}
                                bordered={false}
                                options={options}
                            />, <span>Running instances {el.instances}</span>,
                            <span>Incidents {el.incidents ? el.incidents.length : null}</span>

                        ]}
                        style={{ width: "100%", height: "100%" }}>
                        <MyDiagramViewer data={el.bpmn20Xml} formId={'diagram' + index} />
                    </Card>
                </Col>)
            })
        }
        let main = <div style={{ display: "flex", padding: "100px", width: "100%", justifyContent: "center" }}>
            <Spin size="large" tip={<><span>Loading  </span><span className="countDeployedProcess">0</span>/<span className="allDeployedProceses">0</span></>}></Spin>
        </div>;
        if (this.state.deployedProcess.length > 0) {
            main = <><Col span={24}><Search
                placeholder="Search text"
                allowClear
                onChange={this.onChange}
                onSearch={this.onSearch}

            /></Col>{card}</>
        }
        let modal =
            <Modal title="Deployed process" width={1300} height={800} open={this.state.visible} destroyOnClose={true} onCancel={this.handleCancel} key={'modalDiagramView'} footer={false}>
                < Row gutter={[24, 24]} style={{ maxHeight: '800px', overflow: "scroll", overflowX: "hidden", paddingRight: "30px" }}>
                    {main}
                </Row >

            </Modal>

        let name = this.props.type === 'link' ? <Tooltip placement="bottom" title="Deployed process">
            <CloudServerOutlined style={{ color: '#595959', fontSize: '20px' }} />
        </Tooltip> : <span><CloudServerOutlined />  Deployed process</span>;
        return (
            <>
                {modal}
                <Button type={this.props.type} size={this.props.type === 'link' ? 'default' : 'large'} style={this.props.type === 'primary' ? { backgroundColor: '#223366' } : { padding: '0' }} block onClick={this.showModal}>{name}</Button>
            </>
        );
    };
}

export default ModalDiagramView;
